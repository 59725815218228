// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-complaints-policy-js": () => import("./../../../src/pages/complaints-policy.js" /* webpackChunkName: "component---src-pages-complaints-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-enformer-index-js": () => import("./../../../src/pages/enformer/index.js" /* webpackChunkName: "component---src-pages-enformer-index-js" */),
  "component---src-pages-finding-true-wealth-index-js": () => import("./../../../src/pages/finding-true-wealth/index.js" /* webpackChunkName: "component---src-pages-finding-true-wealth-index-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-important-information-js": () => import("./../../../src/pages/important-information.js" /* webpackChunkName: "component---src-pages-important-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-self-serve-index-js": () => import("./../../../src/pages/self-serve/index.js" /* webpackChunkName: "component---src-pages-self-serve-index-js" */),
  "component---src-pages-services-for-clients-js": () => import("./../../../src/pages/services-for-clients.js" /* webpackChunkName: "component---src-pages-services-for-clients-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

